import { http } from "../utils/service";

import { DocInfo, RoleType } from "@/types";

export enum BookType {
  init = 0,
  system = 1,
  project = 2,
  history = 3,
}

export enum Attribute {
  public = 1,
  private = 2,
}

export interface RoleInfo {
  active: number;
  avatar: string;
  email: string;
  id: number;
  name: string;
  phone: string;
  roles: string;
  sex: "female" | "male";
  unionid: string;
}
export interface DocBookInfo {
  attribute: Attribute;
  created: number;
  created_by: string | number;
  description: string;
  icon: string;
  id: number;
  name: string;
  number: number;
  role: RoleType;
  role_count: number;
  role_info: Array<RoleInfo | Member>;
  updated: number;
  [propsName: string]: unknown;
}
export interface DocChatInfo {
  chat_global_id: string;
  chat_name: string;
  created: number;
  id: number;
  last_open_time: number;
  post: DocInfo;
  post_id: number;
  role: RoleType;
  updated: number;
  user_id: number;
  [propsName: string]: unknown;
}

export interface Member {
  avatar: string;
  id: number;
  name: string;
  role: RoleType;
  user_id?: number;
  [_: string]: unknown;
}

/**
 *
 * @description 获取文档详细信息
 */
export function getDocDetails(data: unknown): Promise<Global.ResData<DocInfo>> {
  return http({
    url: "/api_doc/post/find",
    method: "post",
    data,
  });
}
/**
 *
 * @description 更新文档
 */
export function updateDoc(data: unknown): Promise<Global.ResData<DocInfo>> {
  return http({
    url: "/api_doc/post/update",
    method: "post",
    data,
  });
}

/**
 *
 * @description 获取知识页信息
 */
export function getBookInfo(data: unknown): Promise<Global.ResData<Array<DocBookInfo>>> {
  return http({
    url: "/api_doc/item/get_book_type",
    method: "post",
    data,
  });
}

/**
 *
 * @description 获取文件历史记录
 */
export function getPostRecords(
  data: unknown
): Promise<Global.ResData<Array<DocChatInfo>>> {
  return http({
    url: "/api_doc/post_record/select",
    method: "post",
    data,
  });
}

/**
 *
 * @description 删除单个文件历史记录
 */
export function deletePostRecords(data: unknown): Promise<Global.ResData<number>> {
  return http({
    url: "/api_doc/post_record/delete",
    method: "post",
    data,
  });
}
/**
 *
 * @description 查看知识库成员
 */
export function getMembers(data: unknown): Promise<Global.ResData<Array<Member>>> {
  return http({
    url: "/api_doc/member/select",
    method: "post",
    data,
  });
}
/**
 *
 * @description 更新成员信息
 */
export function updateMember(data: unknown): Promise<Global.ResData<Member>> {
  return http({
    url: "/api_doc/member/update",
    method: "post",
    data,
  });
}
/**
 *
 * @description 踢出成员
 */
export function deleteMember(data: unknown): Promise<Global.ResData<number>> {
  return http({
    url: "/api_doc/member/delete",
    method: "post",
    data,
  });
}
/**
 *
 * @description 添加新成员
 */
export function addMember(data: unknown): Promise<Global.ResData<number>> {
  return http({
    url: "/api_doc/member/create",
    method: "post",
    data,
  });
}
/**
 *
 * @description 获取树
 */
export function getKnowledgeBookTree(
  data: unknown
): Promise<Global.ResData<Array<DocInfo>>> {
  return http({
    url: "/api_doc/post/get_knowledge_book_tree",
    method: "post",
    data,
  });
}
/**
 *
 * @description 创建树结构 cell
 */
export function createKnowledgeBookTree(data: unknown): Promise<Global.ResData<DocInfo>> {
  return http({
    url: "/api_doc/post/create_knowledge_book_tree",
    method: "post",
    data,
  });
}
/**
 *
 * @description 树节点的删除
 */
export function deleteKnowledgeBookTree(data: unknown): Promise<Global.ResData<unknown>> {
  return http({
    url: "/api_doc/post/delete_knowledge_book_tree",
    method: "post",
    data,
  });
}
/**
 *
 * @description 更新树
 */
export function updateKnowledgeBookTree(data: unknown): Promise<Global.ResData<unknown>> {
  return http({
    url: "/api_doc/post/update_knowledge_book_tree",
    method: "post",
    data,
  });
}
/**
 *
 * @description 更新文件记录数据
 */
export function updateHistory(data: unknown): Promise<Global.ResData<unknown>> {
  return http({
    url: "/api_doc/post_record/update",
    method: "post",
    data,
  });
}
/**
 *
 * @description 获取单个文件历史记录
 */
export function findPostRecord(data: unknown): Promise<Global.ResData<DocChatInfo>> {
  return http({
    url: "/api_doc/post_record/find",
    method: "post",
    data,
  });
}
/**
 *
 * @description 创建知识库
 */
export function createBook(data: unknown): Promise<Global.ResData<unknown>> {
  return http({
    url: "/api_doc/book/create",
    method: "post",
    data,
  });
}
/**
 *
 * @description 编辑知识库
 */
export function updateBook(data: unknown): Promise<Global.ResData<unknown>> {
  return http({
    url: "/api_doc/book/update",
    method: "post",
    data,
  });
}
/**
 *
 * @description 删除知识库
 */
export function deleteBook(data: unknown): Promise<Global.ResData<unknown>> {
  return http({
    url: "/api_doc/book/delete",
    method: "post",
    data,
  });
}
