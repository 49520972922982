import { ref, watch } from "vue";

import { getDocDetails, updateDoc, updateHistory } from "../../api/doc";

import { currentDocInfo, set, getVal, isReadonly, isChange, docEmitter } from "./index";

import { message } from "ant-design-vue";

import { currentInfo, chromeTabs, currentNav } from "@/layouts/basicLayout";

import { selected } from "@/views/editor/module/left-nav";

import { sendMsg } from "@/api/im";

import { container } from "@/components/aomao-editor/index";
import { DocType } from "@/types";

const docName = ref("");

/**
 *
 * @param id  需要查询的文档id
 * @description 查询文档 并进行赋值 文档type 详情
 */
const find = (id: number): void => {
  const data = {
    param: {
      filter: {
        id,
      },
    },
  };

  getDocDetails(data).then((res) => {
    if (res.code === 0 && res.data) {
      set(res.data);
    }
  });
};

/**
 * 监听文档对象  触发对文档名称的更改
 */
watch(
  currentDocInfo,
  () => {
    docName.value = currentDocInfo.value?.name || "";
  },
  {
    deep: true,
  }
);

/**
 * @description 编辑文档或目录名称 当前不存在不允许保存
 */
const editName = (): void => {
  if (!currentDocInfo.value) return;
  const id = currentDocInfo.value?.id;

  const name = docName.value;

  const data = {
    data: {
      id,
      name,
    },
  };

  updateDoc(data).then((res) => {
    if (res && res.code === 0 && res.data) {
      currentDocInfo.value && (currentDocInfo.value.name = docName.value);

      message.success("操作成功!");

      if (currentInfo.value?.realId && currentInfo.value?.post_id === id) {
        chromeTabs.value?.updateTab(
          currentNav.value,
          Object.assign(currentInfo.value, { name })
        );
      } else if (selected.value && selected.value?.id === id) {
        selected.value.name = name;
      }
    }
  });
};

/**
 * @description 保存文档 目前 只读 和 文档不存在不允许保存 无改变的文档 为假性保存
 */
const save = (
  val: { noTip?: unknown; autoSave?: unknown; cb?: () => void } = {}
): void => {
  const { noTip, cb, autoSave } = val;
  if (isReadonly.value || !currentDocInfo.value) return;
  // if (!isChange.value) {
  //   noTip !== true && message.success("保存成功！");
  //   return;
  // }
  console.log(autoSave);

  if (autoSave && currentDocInfo.value?.file_type === DocType.draw) return;

  if (currentInfo.value?.realId) {
    const data = {
      data: {
        id: currentInfo.value?.realId,
        operation: "edit",
      },
    };
    updateHistory(data);
  }

  const { id, catalog_id, name, item_id, comments, number } = currentDocInfo.value;

  const data = {
    data: {
      id,
      catalog_id,
      name,
      item_id,
      content: "",
      comments,
      number,
    },
  };

  const update = () => {
    updateDoc(data)
      .then((res) => {
        if (res && res.code === 0 && res.data) {
          currentDocInfo.value = res.data;
          noTip !== true && message.success("保存成功！");
          isChange.value = false;
        }
      })
      .finally(() => {
        cb && cb();
      });
  };
  getVal().then((res) => {
    data.data.content = res;
    update();
  });
};

const share = (): void => {
  save({ noTip: true });

  let text = "";

  switch (currentDocInfo.value?.file_type) {
    case DocType.word:
      text = container.value?.textContent?.replace(/[\n]/g, "") || "";

      if (text.length > 200) {
        text = text.substring(0, 200) + "...";
      }
      break;
    case DocType.excel:
      text = "Teamones表格文档";
      break;
    case DocType.draw:
      text = "Teamones画板文档";
      break;
  }

  const data = {
    data: {
      chat_global_id: currentInfo.value?.chat_global_id,
      type: "normal",
      content: {
        title: currentDocInfo.value?.name || "",
        content: text,
        url: location.origin,
        url_extra: {
          id: currentInfo.value?.post_id,
          file_type: currentDocInfo.value?.file_type,
        },
      },
      content_type: "link",
    },
  };

  sendMsg(data).then((res) => {
    if (res && res.code === 0) {
      message.success("分享成功！");

      window.opener?.postMessage({
        type: "share",
        data: res.data,
      });
    }
  });
};

docEmitter.on("ctrlS", (res: any) => {
  if (res && res.autoSave) {
    save({ noTip: true, autoSave: res.autoSave });
  } else {
    if (res) {
      save({ noTip: true });
    } else {
      save();
    }
  }
});

export { currentDocInfo, find, docName, editName, save, share };
