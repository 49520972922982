import { ref, watch, nextTick } from "vue";

import router from "@/router";

import {
  DocChatInfo,
  findPostRecord as _findPostRecord,
  DocBookInfo,
  updateHistory as _updateHistory,
  getBookInfo as _getBookInfo,
} from "@/api/doc";

import { getEnumHas } from "@/utils";

// import { find, changeSave } from "@/hooks/doc-editor/data-processing";

import { getKnowledgeBookTree } from "@/views/editor/module/left-nav";

// import { operate, setContent } from "@/hooks/doc-editor/index";

import { variate } from "@/styles";
import { message } from "ant-design-vue";

import { DocType, RoleType, Param, ChromeTabsAPI } from "@/types";

import { find } from "@/views/doc/data-processing";
import { isReadonly } from "@/views/doc/index";

// 当前导航 id
const currentNav = ref("-1");

// 导航栏实列dom
const chromeTabs = ref<ChromeTabsAPI | null>(null);

// 当前导航详细信息
const currentInfo = ref<Param | null>(null);

watch(currentInfo, (val, old) => {
  if (old && !val) {
    window.opener.postMessage({
      type: "close",
      name: "kenoledges",
    });
  }
});

// 第一次加载处理
const isShow = ref(false);

/**
 *
 * @param post_id 文档id
 * @returns promise
 * @description 查询导航栏需要的文档信息
 */
const getHistoryDoc = (
  post_id: number | string,
  global_id: number | string
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const data = {
      param: {
        filter: {
          post_id,
          global_id,
        },
      },
    };

    _findPostRecord(data)
      .then((res) => {
        if (res && res.code === 0 && res.data) {
          look(res.data);
          resolve(res.data);
        } else if (res && res.code === 0) {
          message.error("数据不存在哦！");
          router.push("/404");
          reject();
        } else {
          router.push("/404");
          reject();
        }
      })
      .catch(() => {
        router.push("/404");
        reject();
      });
  });
};

const getBookInfo = (doc_id: number | string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const data = {
      param: {
        filter: {
          id: doc_id,
        },
        page: [1, 30],
      },
    };
    _getBookInfo(data)
      .then((res) => {
        if (res && res.code === 0 && res.data && res.data[0]) {
          lookBook(res.data[0]);
          resolve(res.data);
        } else if (res && res.code === 0) {
          message.error("数据不存在哦！");
          router.push("/404");
          reject();
        } else {
          router.push("/404");
          reject();
        }
      })
      .catch(() => {
        router.push("/404");
        reject();
      });
  });
};

/**
 *
 * @param el 导航栏需要的文档信息
 * @description 将导航栏需要的文档信息 做出一些处理，载入 导航实列
 */
const look = (el: DocChatInfo): void => {
  const obj = JSON.parse(JSON.stringify(el));

  const data = {
    data: {
      id: obj.id,
      operation: "open",
    },
  };
  _updateHistory(data);
  obj.name = obj.post.name;

  switch (obj.post?.file_type) {
    case DocType.word:
      obj.icon = "word";
      break;
    case DocType.excel:
      obj.icon = "exl";
      break;
    case DocType.draw:
      obj.icon = "huabanmianxing";
      break;
  }

  obj.realId = obj.id;
  obj.id = "post" + obj.post_id;

  const copy = chromeTabs.value?.list.find((el) => el.id === obj.id);

  if (copy) {
    currentNav.value = obj.id;
  } else {
    chromeTabs.value?.addTab(obj, true);
  }
};

const lookBook = (obj: DocBookInfo): void => {
  const copy = chromeTabs.value?.list.find((el) => el.id === obj.id);

  if (copy) {
    currentNav.value = String(obj.id);
  } else {
    chromeTabs.value?.addTab(obj, true);
  }
};

watch(currentNav, (val) => {
  nextTick(() => {
    // changeSave();

    // setContent("");

    const info = chromeTabs.value?.currentInfo || null;

    // 同步更新 currentInfo
    currentInfo.value = info;

    // 清理编辑器的数据
    // 是否只读的权限操作处理
    {
      if (info && getEnumHas(RoleType, info.role) && info.role !== RoleType.viewer) {
        isReadonly.value = false;
      } else {
        isReadonly.value = true;
      }
    }

    if (info) {
      // 拿取数据的处理
      {
        if (info.realId) {
          find(info.post_id as number);
        } else if (info.id !== "-1" && !isNaN(Number(info.id))) {
          getKnowledgeBookTree(Number(info.id));
        }
      }

      // 路由切换处理
      {
        if (val === "-1") {
          router.push("/home");
          variate["header-h"] = "50px";
          variate["sider-w"] = 180;
        } else {
          router.push(`/editor?doc_id=${val}`);

          if (info.realId) {
            variate["sider-w"] = 0;
          } else {
            variate["sider-w"] = 253;
          }
          variate["header-h"] = "60px";
        }
      }
    }
  });
});

export {
  currentNav,
  chromeTabs,
  currentInfo,
  look,
  lookBook,
  getHistoryDoc,
  getBookInfo,
  isShow,
};
