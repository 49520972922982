import { ref, watch, computed } from "vue";

import {
  getKnowledgeBookTree as _getKnowledgeBookTree,
  createKnowledgeBookTree as _createKnowledgeBookTree,
  deleteKnowledgeBookTree as _deleteKnowledgeBookTree,
  updateKnowledgeBookTree as _updateKnowledgeBookTree,
} from "@/api/doc";

import type { AntTreeNodeDropEvent } from "ant-design-vue/es/tree";

import { currentInfo } from "@/layouts/basicLayout";
import { message } from "ant-design-vue";

import { DocType, DocInfo } from "@/types";

import { currentDocType } from "@/views/doc";
import { currentDocInfo, find } from "@/views/doc/data-processing";

import { getEnumHas } from "@/utils";

type Level = {
  level: number;
  id: number;
  node: DocInfo;
};

// tree 数据
const treeData = ref<Array<DocInfo>>([]);
// tree 选择的key 双向绑定
const selectedKeys = ref<Array<number | string>>([""]);

// tree 选择的数据 需要自己去指向
const selected = ref<DocInfo | null>(null);

const spinning = ref(false);

watch(selected, (val) => {
  if (val && getEnumHas(DocType, val?.file_type) && val?.file_type !== DocType.init) {
    if (val.file_type !== DocType.catalog) {
      find(val.id || 0);
    } else {
      currentDocType.value = DocType.catalog;

      currentDocInfo.value = {
        id: val.id,
        name: val.name,
        file_type: DocType.catalog,
      };
    }
  } else {
    currentDocType.value = DocType.init;
  }
});

const visible = computed(() => {
  let judge = true;

  if (
    (selected.value?.file_type === DocType.catalog || !selected.value) &&
    !currentInfo.value?.realId &&
    currentInfo.value?.id !== "-1"
  ) {
    judge = false;
  }

  return judge;
});

/**
 *
 * @param id 知识页id
 * @description 获取树 数据
 */
const getKnowledgeBookTree = (id: number): void => {
  spinning.value = true;
  treeData.value = [];
  selected.value = null;
  selectedKeys.value = [""];
  const data = {
    param: {
      filter: {
        book_type_id: id,
      },
    },
  };

  _getKnowledgeBookTree(data)
    .then((res) => {
      if (res && res.code === 0 && res.data) {
        treeData.value = res.data;
      }
      spinning.value = false;
    })
    .catch(() => {
      spinning.value = false;
    });
};

/**
 *
 * @param id 查询的id
 * @param trees 树的数组对象
 * @returns { isHas } tree 是否有这个id 的节点
 * @returns { list } 查询的节点的那一层的数据
 * @returns { parent } 查询节点的父级
 * @description 树节点的递归查询操作
 */
const recursionTree = (
  id: number | string,
  trees: Array<DocInfo> = treeData.value,
  parentNode: DocInfo | null = null
): {
  isHas: boolean;
  list: Array<DocInfo>;
  parent: DocInfo;
} => {
  let isHas = false;
  let list: Array<DocInfo> = [];
  let parent: DocInfo | null = null;
  if (trees.find((el) => el.id === id)) {
    isHas = true;
    list = trees;
    parent = parentNode;
  } else {
    trees.forEach((el) => {
      if (el.catalog) {
        const obj = recursionTree(id, el.catalog, el);
        if (obj.isHas) {
          isHas = true;
          list = obj.list;
          parent = obj.parent;
          return;
        }
      }
    });
  }

  if (!parent) {
    parent = {
      id: 0,
      file_type: DocType.catalog,
      catalog: treeData.value,
    };
  }

  return { isHas, list, parent };
};

/**
 *
 * @param val 查询的节点
 * @param id 附带查询到 id 所在的层数
 * @param level 层数累计
 * @param list 查询的结果
 * @description 查询树节点的层数
 */
const recursionLevel = (
  list: Array<Level> = [],
  val: DocInfo = {
    id: 0,
    file_type: DocType.catalog,
    catalog: treeData.value,
  },
  id?: number,
  level = 0
) => {
  level++;

  val.catalog?.forEach((el) => {
    if (id && el.id === id) {
      list.push({
        level,
        id,
        node: el,
      });
    } else if (!el.catalog || !el.catalog.length) {
      list.push({
        level,
        id: el.id || 0,
        node: el,
      });
    } else {
      recursionLevel(list, el, id, level);
    }
  });
};

// 删除， 待调试
const del = (row: DocInfo): void => {
  const nodeId = row.id;

  if (!nodeId) return;

  const date = {
    param: {
      filter: {
        id: row.id,
      },
    },
  };
  _deleteKnowledgeBookTree(date).then((res) => {
    if (res && res.code === 0 && res.data) {
      message.success("操作成功！");
      const back = recursionTree(nodeId);

      if (back.isHas) {
        const idx = back.list.findIndex((el) => el.id === row.id);

        if (idx !== -1) {
          if (
            row.id === selectedKeys.value[0] ||
            recursionTree(selectedKeys.value[0], row.catalog)
          ) {
            selectedKeys.value = [""];
            selected.value = null;
          }

          back.list.splice(idx, 1);
        }
      }
    }
  });
};

/**
 *
 * @param info 拖拽的事件节点
 * @returns void
 * @description 拖拽的事件处理
 */

const onDrop = (info: AntTreeNodeDropEvent): void => {
  // true 外部 false 内部
  const dropToGap = info.dropToGap;
  // 拖拽的节点
  const dragNode = info.dragNode;
  // 目标节点
  const targetNode = info.node;

  const dragObj = recursionTree(dragNode.id);

  const targetObj = recursionTree(targetNode.id);

  const drag = dragObj.list.find((el) => el.id === dragNode.id);

  const target = targetObj.list.find((el) => el.id === targetNode.id);

  if (!drag || !target) return;

  // 拖拽的节点当前位置
  const idx = dragObj.list.findIndex((el) => el.id === dragNode.id);

  const dragResults: Array<Level> = [];

  const targetResults: Array<Level> = [];

  recursionLevel(dragResults, dragNode.dataRef);
  recursionLevel(targetResults, undefined, targetNode.id);
  dragResults.sort((a, b) => {
    return b.level - a.level;
  });

  const targetLevel = targetResults.find((el) => el.id === targetNode.id)?.level || 0;
  const dragLevel = (dragResults[0]?.level || 0) + 1;
  const setLocation = (id: number, idList: unknown) => {
    const datas = {
      data: {
        id: drag.id,
        parent_id: id,
        sort: idList,
        // parent_id: dropNode.parent.data.id || dropNode.data.id
      },
    };
    console.log(`target`, idList);
    _updateKnowledgeBookTree(datas).then((res) => {
      if (res && res.code === 0 && res.data) {
        message.success("操作成功！");
      }
    });
  };

  if (!dropToGap) {
    // 内部
    if (targetLevel + dragLevel > 4) {
      message.warning("文档仅支持4层结构");
    } else if (
      dragNode.file_type === DocType.catalog &&
      targetNode.file_type !== DocType.catalog
    ) {
      message.warning("文件下不应存在目录");
    } else {
      // const isSet = target?.catalog?.find((el) => el.id === drag.id);

      dragObj.parent?.catalog?.splice(idx, 1);

      if (target.catalog) {
        target.catalog.unshift(drag);
      } else {
        target.catalog = [drag];
      }
      const idList = target.catalog?.map((item) => {
        return item.id;
      });
      setLocation(target.id || 0, idList);
    }
  } else {
    // 外部
    if (targetLevel - 1 + dragLevel > 4) {
      message.warning("文档仅支持4层结构");
    } else if (
      dragNode.file_type === DocType.catalog &&
      targetObj.parent?.file_type !== DocType.catalog
    ) {
      message.warning("文件下不应存在目录");
    } else {
      // const isSet = targetObj.parent?.catalog?.find((el) => el.id === drag.id);

      dragObj.parent?.catalog?.splice(idx, 1);

      const index = targetObj.parent?.catalog?.findIndex((el) => el.id === target.id);
      if (typeof index === "number" && index !== -1) {
        targetObj.parent?.catalog?.splice(index + 1, 0, drag);
      }
      const idList = targetObj.list?.map((item) => {
        return item.id;
      });
      setLocation(targetObj.parent?.id || 0, idList);
    }
  }
};

/**
 *
 * @param key 创建的节点类型
 * @param row 创建的节点的上级节点信息
 * @returns void
 * @description 创建一个树的结点
 */
const createKnowledgeBookTree = (
  key: DocType,
  row?: DocInfo & { data: DocInfo }
): void => {
  if (!currentInfo.value) return;

  const results: Array<Level> = [];

  recursionLevel(results, undefined, row?.id || 0);

  const level = results.find((el) => el.id === row?.id || 0)?.level || 0;

  if (level >= 4) {
    message.warning("文档仅支持4层结构");
    return;
  }

  let name = "";
  switch (key) {
    case DocType.catalog:
      name = "新文件夹";
      break;
    case DocType.word:
      name = "新文档";
      break;
    case DocType.excel:
      name = "新表格";
      break;
    case DocType.draw:
      name = "新画板";
      break;
  }

  const data = {
    data: {
      alter: false,
      book_type_id: currentInfo.value?.id || "",
      catalog: [],
      file_type: key,
      name,
      parent_id: row?.id || 0,
    },
  };

  _createKnowledgeBookTree(data).then((res) => {
    if (res && res.code === 0 && res.data) {
      res.data.id = Number(res.data.id);

      if (row) {
        if (row.catalog) {
          row.catalog.unshift(res.data);
        } else {
          row.catalog = [res.data];
        }
      } else {
        treeData.value.unshift(res.data);
      }

      selectedKeys.value = [res.data.id || 0];
      selected.value = res.data;
    }
  });
};

export {
  treeData,
  selectedKeys,
  del,
  createKnowledgeBookTree,
  selected,
  getKnowledgeBookTree,
  visible,
  onDrop,
  spinning,
};
